import {styled} from '../../theme';

export const ArrowUp = styled.div`
  width: 0;
  height: 0;

  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
  border-bottom: 15px solid white;

  margin: 0 auto;
`;

export const Dropdown = styled.li`
  display: flex;
  flex-direction: column;
  transition: all 0.25s ease-in-out;
  font-weight: 700;
  margin: 0;

  > a {
    padding: ${({theme}) => theme.spacing(1.5)} 0;
  }

  &:hover > .DropdownContent,
  a:hover + .DropdownContent,
  .DropdownContent:hover {
    opacity: 1;
    visibility: visible;
  }
`;

export const DropdownContent = styled.ul`
  position: absolute;
  z-index: 10;

  justify-self: center;
  align-self: center;
  width: max-content;
  top: 24px;
  margin-top: 24px;
  padding: ${({theme}) => theme.spacing(4)};

  visibility: hidden;
  opacity: 0;

  transition: all 0.25s ease-in-out;
  background-color: white;
  filter: drop-shadow(0px 4px 18px rgba(0, 0, 0, 0.2));
  border-radius: 10px;

  // arrow
  &:before {
    display: block;
    position: absolute;

    // offset height of triangle aka border bottom
    top: -18px;
    //  (width of box + width of triangle) / 2
    left: calc(50% - 20px);

    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 18px solid white;

    visibility: visible;
    content: ' ';
  }
`;
