import {useMemo} from 'react';

import {MenuItem} from '../../types/pageData';
import {usePageQuery, getPageImage} from './usePageQuery';

export function useNestedMenu() {
  const {
    data: {
      allMdx: {nodes},
      allFile,
    },
  } = usePageQuery();

  return useMemo(() => {
    return nodes.reduce<MenuItem[]>(
      (
        acc = [],
        {
          id,
          tableOfContents: {items: tableOfContents},
          frontmatter: {title, slug, parent, linked, image, noMenuIcon, hidden},
        },
        _,
        list,
      ) => {
        // items with no slug do not appear on menu
        if (!slug) {
          return acc;
        }

        const imagePath = getPageImage(allFile, image);

        // if item has no parent, just append it as a root-level item
        if (!parent && id && slug && !acc.find((item) => item.id === id)) {
          return [
            ...acc,
            {
              id,
              slug,
              title,
              linked,
              hidden,
              image,
              imagePath,
              tableOfContents,
              noMenuIcon,
            },
          ];
        }

        // parent was already accumulated, update it
        const parentAccIndex = acc.findIndex(
          (parentNode) => parentNode.slug === parent,
        );
        if (parentAccIndex > -1) {
          const parent = acc[parentAccIndex];
          const siblings = parent.children || [];
          const value = {
            ...parent,
            children: [
              ...siblings,
              {
                id,
                slug,
                title,
                linked,
                hidden,
                tableOfContents,
                image,
                imagePath,
                noMenuIcon,
                parent: parent.slug,
              },
            ],
          };

          acc[parentAccIndex] = value;
          return acc;
        }

        // parent wasn't alerady accumulated, insert it
        const parentListIndex = list.findIndex(
          (parentNode) => parentNode.frontmatter.slug === parent,
        );

        if (parentListIndex > -1) {
          const parent = {
            id: list[parentListIndex].id,
            slug: list[parentListIndex].frontmatter.slug,
            title: list[parentListIndex].frontmatter.title,
            linked: list[parentListIndex].frontmatter.linked,
            hidden: list[parentListIndex].frontmatter.hidden,
            parent: list[parentListIndex].frontmatter.parent,
            image: list[parentListIndex].frontmatter.image,
            noMenuIcon: list[parentListIndex].frontmatter.noMenuIcon,
            imagePath,
            tableOfContents: list[parentListIndex].tableOfContents.items,
          };

          const value: MenuItem = {
            ...parent,
            children: [
              {
                id,
                slug,
                title,
                linked,
                hidden,
                tableOfContents,
                image,
                imagePath,
                noMenuIcon,
                parent: parent.slug,
              },
            ],
          };

          return acc.concat([value]);
        }

        return acc;
      },
      [],
    );
  }, [allFile, nodes]);
}
