import React, {useCallback, useState} from 'react';
import {Link} from 'gatsby';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';

import {styled} from '../../theme';
import {DropdownContent, Dropdown} from './Dropdown';
import {MenuItem} from '../../../types/pageData';
import {useNestedMenu} from '../../hooks/useNestedMenu';
import {PaperAccordion, PaperAccordionSummary} from '../PaperAccordion';

const MenuList = styled.ul`
  display: flex;
  align-items: center;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;

  & > li {
    display: flex;
    position: relative;

    :hover > a {
      color: ${({theme}) => theme.palette.primary.main};
    }

    & > a {
      // only make root uppercase
      text-transform: uppercase;
      padding: ${({theme}) => theme.spacing(1.5)};
      padding-bottom: ${({theme}) => theme.spacing(2)};
      margin-bottom: -${({theme}) => theme.spacing(2)};
      margin-top: -${({theme}) => theme.spacing(1.5)};
    }

    ul {
      position: absolute;
    }
  }

  a {
    text-decoration: none;
    color: ${({theme}) => theme.palette.common.black};
    transition: color 0.25s ease-in-out;

    :hover {
      color: ${({theme}) => theme.palette.primary.main};
    }
  }

  // margin between elements, not around
  & > *:not(:nth-child(1)) {
    margin-left: ${({theme}) => theme.spacing(2)};
  }
`;

const MenuListItem = styled(Link)`
  display: flex;
  align-items: center;
  padding: ${({theme}) => theme.spacing(1)} 0;
`;

const ListItemIcon = styled.div`
  background-color: #f2f2f2;

  height: 45px;
  width: 45px;

  border-radius: calc(45px / 2);

  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${({theme}) => theme.spacing(2)};

  img {
    max-height: 25px;
    max-width: 25px;
    margin: 0;
  }
`;

export interface Props {
  data: MenuItem[];
}

function RecursiveItem({
  slug,
  title,
  linked,
  imagePath,
  noMenuIcon,
  hidden,
  children,
}: MenuItem) {
  if (hidden) {
    return null;
  }

  return (
    <Dropdown className="Dropdown">
      {slug && linked !== false ? (
        <MenuListItem to={slug}>
          {!noMenuIcon && imagePath && (
            <ListItemIcon>
              <img src={imagePath || ''} alt={title} />
            </ListItemIcon>
          )}
          {title}
        </MenuListItem>
      ) : (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a>{title}</a>
      )}

      {children && children.length > 0 && (
        <DropdownContent className="DropdownContent">
          {children.map((child) => (
            <RecursiveItem key={child.id} {...child} />
          ))}
        </DropdownContent>
      )}
    </Dropdown>
  );
}

export function Menu() {
  const data = useNestedMenu();

  return (
    <MenuList>
      {data?.map((item) => (
        <RecursiveItem key={item.id} {...item} />
      ))}
    </MenuList>
  );
}

export default Menu;

const CollapsedMenuWrapper = styled.div`
  * {
    text-decoration: none !important;
    color: black !important;
  }
`;

export const CollapsedMenu = () => {
  const data = useNestedMenu();
  const [opened, setOpened] = useState('');

  const toggle = useCallback(
    (id: string) => () => {
      setOpened((currentId) => (currentId === id ? '' : id));
    },
    [setOpened],
  );

  return (
    <CollapsedMenuWrapper>
      {data?.map(({id, linked, slug, title, children}) =>
        linked !== false ? (
          <Link key={id} to={slug}>
            <PaperAccordionSummary>{title.toUpperCase()}</PaperAccordionSummary>
          </Link>
        ) : (
          <PaperAccordion
            key={id}
            title={title.toUpperCase()}
            open={opened === id}
            toggle={toggle(id)}
          >
            {children && (
              <List>
                {children?.map(({id, slug, title, imagePath, noMenuIcon}) => (
                  <ListItem key={id} button component={Link} to={slug}>
                    {imagePath && !noMenuIcon && (
                      <ListItemAvatar>
                        <ListItemIcon>
                          <img src={imagePath} alt={title} />
                        </ListItemIcon>
                      </ListItemAvatar>
                    )}
                    <ListItemText>{title}</ListItemText>
                  </ListItem>
                ))}
              </List>
            )}
          </PaperAccordion>
        ),
      )}
    </CollapsedMenuWrapper>
  );
};
