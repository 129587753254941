import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/core/Alert';

import {AlertContext, AlertState} from '@/hooks/useAlertContext';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import {Content, Page, StretchContainer} from './styled';
import SEO from '../../components/SEO';
import {usePageQuery} from '../../hooks/usePageQuery';
import PageTitle from '../../components/PageTitle';

interface Props {
  contained?: boolean;
}

function Main(props: React.PropsWithChildren<Props>) {
  const {children, contained = true} = props;
  const {current} = usePageQuery();
  const alertContext = useState<AlertState>({open: false, severity: 'info'});

  return (
    <AlertContext.Provider value={alertContext}>
      <main>
        <SEO />
        <Page>
          <Header />
          <StretchContainer>
            <Content>
              {!!current?.frontmatter?.showTitle && (
                <PageTitle>
                  <Typography variant="h1">
                    {current?.frontmatter?.title}
                  </Typography>
                </PageTitle>
              )}
              {contained ? <Container>{children}</Container> : children}
            </Content>
            <Footer />
          </StretchContainer>
        </Page>
      </main>

      <Snackbar
        open={alertContext[0].open}
        onClose={() => alertContext[1]((prev) => ({...prev, open: false}))}
        anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
      >
        <Alert severity={alertContext[0].severity}>
          {alertContext[0].message}
        </Alert>
      </Snackbar>
    </AlertContext.Provider>
  );
}

export default Main;
