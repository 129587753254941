import React from 'react';
import {Helmet} from 'react-helmet';
import favicon from '/src/assets/favicon.png';

import {usePageQuery} from '../hooks/usePageQuery';

const defaultTitle = 'TaleFin Bank Statements';
const defaultImageName = 'thumbnail_white_200x200.png';
const defaultDescription = `TaleFin provides Fintech and Developers with the tools to obtain customer consented transaction data and insights. Australia, Bank Statements, Data Aggregation.`;

export function SEO() {
  const {current, data} = usePageQuery();

  const defaultImage =
    data.allFile.edges?.find(
      (edge) => edge.node.relativePath === defaultImageName,
    )?.node.publicURL || '';

  const title = current.frontmatter?.title || defaultTitle;
  const image = current.image || current.frontmatter?.image || defaultImage;
  const description = current.frontmatter?.description || defaultDescription;

  return (
    <Helmet>
      <title>{title}</title>
      <meta charSet="utf-8" />
      <meta name="image" content={image} />
      <meta name="description" content={description} />
      <link rel="icon" type="image/png" href={favicon} />

      {/* OpenGraph */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={image} />
      <meta property="og:description" content={description} />
    </Helmet>
  );
}

export default SEO;
