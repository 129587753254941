import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import {styled} from '../../theme';

export const FooterRoot = styled.footer`
  padding: ${({theme}) => theme.spacing(6)} ${({theme}) => theme.spacing(2)};

  &,
  a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    background: ${({theme}) => theme.palette.primary.main};
    color: ${({theme}) => theme.palette.common.white};
    text-decoration: none;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
`;

export const Copyright = styled.small`
  float: right;

  &,
  a {
    color: ${({theme}) => theme.palette.grey['600']};
  }
`;

export const FooterMenuItem = styled.li`
  padding: 0 0 ${({theme}) => theme.spacing(1)} 0;
  margin: 0;
`;

export const MailingListForm = styled.form`
  padding: ${({theme}) => theme.spacing(2)} 0;
`;

export const MailingListInput = styled(TextField)`
  > div {
    background: ${({theme}) => theme.palette.secondary.main};
    border: none;
    padding: 0;
    margin: 0;
  }

  input {
    padding: 0 0 0 ${({theme}) => theme.spacing(1)};
  }
`;

export const SubmitButton = styled(Button)`
  width: 40px;
  height: 38px;
  padding: ${({theme}) => theme.spacing(0.75)};
  margin: 0;
  min-width: unset;
  border-radius: 0 4px 4px 0;

  * {
    padding: 0;
    margin: 0;
    width: auto;
    min-width: unset;
  }
`;

export const SocialLink = styled.a`
  padding-top: ${({theme}) => theme.spacing(2)};
  padding-bottom: ${({theme}) => theme.spacing(2)};
`;

export const SocialIcon = styled.img`
  &:hover {
    filter: drop-shadow(0px 0px 5px ${({theme}) => theme.palette.primary.main});
  }
  
  width: 41px;
  height: 41px;
  
`;
