import React, {PropsWithChildren} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';

import theme, {styled} from '../theme';

const Root = styled(Container)`
  .MuiButton-outlined {
    border-color: ${({theme}) => theme.palette.common.white};
    color: ${({theme}) => theme.palette.common.white};
    background: transparent;
  }

  .MuiButton-contained {
    color: ${({theme}) => theme.palette.primary.main};
    border-color: ${({theme}) => theme.palette.common.white};
    background: ${({theme}) => theme.palette.common.white};
  }
`;

const CustomizedPaper = styled(Paper)`
  background-color: ${({theme}) => theme.palette.primary.main};
  box-shadow: 0 0 0 0;
`;

export interface Props {
  title?: string;
}

export function CallToAction(props: PropsWithChildren<Props>) {
  const {title = 'Start Now', children} = props;

  return (
    <Root maxWidth={false}>
      <CustomizedPaper>
        <Grid container alignItems="center">
          <Grid item padding={3} sx={{margin: {xs: 'auto', md: 0}}}>
            <Typography
              variant="h3"
              color={theme.palette?.common?.white}
              fontWeight={800}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item sx={{margin: {xs: 'auto', md: 0}}}>
            <Grid container spacing={3} justifyContent="center">
              {children}
            </Grid>
          </Grid>
        </Grid>
      </CustomizedPaper>
    </Root>
  );
}

export default CallToAction;
