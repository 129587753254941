import React, {PropsWithChildren, ReactNode, useEffect, useState} from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Collapse from '@material-ui/core/Collapse';

import {styled} from '../theme';

export const PaperAccordionSummary = styled(Box)`
  display: flex;
  align-items: center;
  padding: 0 ${({theme}) => theme.spacing(2)};
  min-height: ${({theme}) => theme.spacing(10)};
  border-top: 0.5px solid lightgrey;
`;

export const PaperAccordionDetails = styled(Box)`
  box-shadow: inset 0px 11px 8px -10px lightgrey,
    inset 0px -11px 8px -10px lightgrey;
  padding: ${({theme}) => theme.spacing(2)};
`;

export const PaperAccordion = (
  props: PropsWithChildren<{
    title: ReactNode;
    open?: boolean;
    toggle?: (state: boolean) => void;
  }>,
) => {
  const [expanded, setExpanded] = useState(props.open || false);

  useEffect(() => {
    if (typeof props.open !== 'undefined' && props.open !== expanded) {
      setExpanded(props.open);
    }
  }, [expanded, props.open]);

  const toggle = () => {
    if (props.children) {
      if (props.toggle) {
        props.toggle(!expanded);
      } else {
        setExpanded((state) => !state);
      }
    }
  };

  return (
    <>
      <PaperAccordionSummary onClick={toggle}>
        <Grid container alignItems="center">
          <Grid item marginRight="auto">
            {props.title}
          </Grid>
          {props.children && (
            <Grid item>
              <IconButton>{expanded ? <RemoveIcon /> : <AddIcon />}</IconButton>
            </Grid>
          )}
        </Grid>
      </PaperAccordionSummary>
      <Collapse in={expanded}>
        <PaperAccordionDetails padding={2}>
          {props.children}
        </PaperAccordionDetails>
      </Collapse>
    </>
  );
};
export default PaperAccordion;
