import React, {useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Stack from '@material-ui/core/Stack';
import {Link} from 'gatsby';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';

import {styled} from '@/theme';
import {useSxLayout} from '@/hooks/useSxLayout';
import TalefinLogo from '@/assets/TaleFin_Logo_Plum_RGB_1600px.png';

import {CollapsedMenu, Menu} from './Menu';
import {DemoForm} from './DemoForm';

const AppBarContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 96px;
  position: sticky;
  z-index: ${({theme}) => theme.zIndex.appBar + 1};
  top: 0;
  height: 96px;
  background: #fff;
`;

const HeaderLogo = () => (
  <Link to="/">
    <img src={TalefinLogo} alt="TaleFin Home" width="180"></img>
  </Link>
);

export const Header = () => {
  const {mobile, desktop} = useSxLayout('flex');

  const [open, setOpen] = useState(false);

  const handleToggle = () => setOpen((state) => !state);

  const handleClose = () => setOpen(false);

  return (
    <AppBarContainer>
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar>
          <Stack
            className="Toolbar-desktop"
            justifyContent="space-between"
            alignItems="center"
            direction="row"
            sx={{flexGrow: 1}}
          >
            <Box>
              <HeaderLogo />
            </Box>

            <Stack sx={desktop} direction="row" spacing={2}>
              <Menu />

              <Button href="/dashboard/">
                Login
              </Button>
              <DemoForm />
            </Stack>

            <MenuIcon onClick={handleToggle} sx={mobile} />
          </Stack>

          <Drawer
            open={open}
            onClose={handleClose}
            anchor="top"
            sx={{zIndex: ({zIndex}) => zIndex.appBar - 1}}
          >
            {/* appbar height + 1 */}
            <div style={{height: 97}} />
            <CollapsedMenu />
            <Grid container padding={2} justifyContent="center" spacing={2}>
              <Grid item>
                <Button variant="outlined" href="/dashboard/">
                  Login
                </Button>
              </Grid>
              <Grid item>
                <DemoForm />
              </Grid>
            </Grid>
          </Drawer>
        </Toolbar>
      </AppBar>
    </AppBarContainer>
  );
};

export default Header;
